module.exports = {
  common: {
    all: 'All',
    checkDetail: 'details',
    save: 'Save',
    submit: 'Submit',
    close: 'Close',
    agree: 'Agree',
    refuse: 'Refuse',
    oprationSuccess: 'Successful operation',
    loginFail: 'Login failed',
    noPermission: 'No Permission'
  },
  dashboard: {
    totalbalance: 'Total balance',
    earnings: "Today's profit",
    yesterdayEarnings: "Yesterday's profit",
    weekEarnings: 'Profit in the past seven days',
    'Today Deposit': 'Top up today',
    'Today Withdraw': 'Withdraw today',

    activeClubs: 'Active clubs',
    newClubs: 'New clubs',
    totalClubs: 'Cumulative clubs',
    activeVips: 'Active agents',
    newVips: 'New agents',
    totalVips: 'Cumulative agencys',
    activeUsers: 'Active users',
    newUsers: 'New users',
    totalUsers: 'Cumulative users',

    totalServerFee: 'Total service charge',
    mttProfit: 'MTT profit',
    'super1 profit': 'Super1 purchase profit',
    'Ordinary table profit': 'Ordinary table profit',
    todayHands: 'Today hands',
    todayGames: 'Today games',

    unionProfit: 'Union profit',
    clubProfit: 'Club profit',
    vipDataDashboard: 'Agent data Dashboard',
    vipData: 'Agent data',
    sharedData: 'Rakeback data'
  },
  vip: {
    statistics: 'Agent revenue Statistics',
    account: 'Username',
    password: 'password',
    ForgotPassword: 'Forgot password',
    customer: 'Contact us',
    login: 'Login',
    ResetPassword: 'Reset password',
    passwordSecurity: ' Please reset your password for account security',
    English: 'English',
    Portuguese: 'Portuguese',
    Chinese: 'Simplified Chinese',
    Setlanguage: 'Language',
    TotalRevenue: 'Total revenue',
    TotalFriends: 'Total friends',
    nextUpdate: 'Next update time',
    LoginExpired: 'Login has expired. Please log in again',
    revenue: 'revenue',
    newAdd: 'New add',
    Active: 'Activity',
    day: 'Only 7 days of data are displayed',
    Error: 'Account/Password error',
    cancel: 'Cancel',
    logOut: 'Log out',
    money: '$',
    completed: 'completed',
    Actualizar: 'Data is updated every hour'
  },
  statistics: {
    login: 'CMS Statistics'
  },
  Today: 'Today',
  Days7: '7Days',
  Days30: '30Days',
  timeZoneTip: 'Time Zone:UTC-0',
  menu: {
    vipStatistics: 'VIP revenue',
    SharedData: 'Profit data',
    MemberManagement: 'Member Management'
  },
  shareData: {
    'ordinary table': 'Ordinary table',
    mtt: 'MTT',
    super1: 'Super1',
    'regular table attendance': 'Ordinary table attendance',
    active_head_time: 'active head',
    'final result': 'Final score',
    'Ordinary tables commission': 'Normal table rebate',
    mttPersonTimes: 'MTT person-times',
    'service fee': 'service charge',
    ordinary_table_num: 'Number of ordinary tables',
    addons_nums: 'addon times',
    'person times': 'Person-times',
    'head times': 'Number of people',
    Winnings: 'Number of winners',

    total_games: 'Total games',
    'total agents nums': 'Total number of agents',
    'affiliated vip': 'Affiliated vip',
    participants_game_num: 'Number of matches played',
    participants_num: 'Participation times',
    buy_times: 'Number of purchases',

    'hand name': 'Game name',
    'hand id': 'Game ID',
    'match id': 'Tournament ID',
    participants_product_name: 'Participating product name',
    participants_product_id: 'Participating product ID',
    is_winner: 'Winner',
    'original record': 'Original record'
  },
  placeholder: {
    memberSearch: 'Please enter player nickname or ID',
    shareDataSearch: 'Please enter a name or ID to search',
    memberType: 'Please select player type',
    phone: 'Please enter phone number',
    password: 'Please enter password',
    email: 'Please enter email'
  },
  daily: {
    title: '数据日报',
    compare: '与昨日同比',
    compare2: '同比昨日',

    profit: '今日收益',
    active_user: '活跃人数',
    hands: '今日手数',
    add_user: '今日新增(人数)',
    table: '总桌数/活跃桌数',
    tables: '总桌数/活跃桌数',
    open_tables: '总桌数',
    avtive_tables: '活跃桌数',
    inavtive_tables: '非活跃桌数',
    user: '直客',
    agent: 'Agent',
    line7: '7-day trend',
    line7_oyo: 'Year-on-year 7-day trend',

    UCAccount: 'UC账户',
    profitData: '收益数据',
    gameTypeData: '玩法数据',
    userData: '用户数据',
    account_banlance: '账户余额',
    club_send: '俱乐部发放',
    club_recyle: '俱乐部回收',
    buy_uc: '购买UC',
    shared_uc: '下分UC',

    total_profit: '总收益',
    vip_user_profit: '代理玩家抽水',
    user_profit: '直客玩家抽水',
    room_profit: '普通桌抽水',
    mtt_profit: 'MTT收益',
    vip_insurance_profit: '代理玩家保险收益',
    insurance_profit: '直客玩家保险收益',
    user_back_profit: '玩家返点',

    total_hand_num: '总手数',
    nlh_hand_num: '德州',
    plo_hand_num: '奥马哈',
    six_plus_hand_num: '6+',
    vip_user_hand_num: '代理玩家',
    user_hand_num: '直客玩家',

    active_user_count: '活跃玩家',
    add_user_count: '新增人数',
    three_day_user_count: '3日留存',
    seven_day_user_count: '7日留存'
  },
  userList: {
    title: 'Member Management',
    agentTitle: 'Junior Member',
    searchPlaceholde: 'ID,nickname,remark',
    ucBanlance: 'UC Banlance',
    diamondBanlance: 'Diamond Banlance',
    nameID: 'Name/ID',
    win: 'Wins',
    hands: 'Hands',
    serverFee: 'Fee',
    insurance: 'Insurance',
    lastLogin: 'Login',
    total: 'total',
    diamond: 'diamond',
    roomRB: 'Common table`s commision',
    mttRB: 'MTT`s commision',
    placeholder1: 'Please enter the quantity to be issuance',
    placeholder2: 'Please enter the quantity to be recycle',
    search: 'search',
    noData: 'No more data',
    confirm: 'confirm',
    rateTip:
      'Note: The current currency and USD are converted according to XE.com real-time exchange rate.'
  },
  userDetail: {
    title: 'Member Details',
    joinTime: 'Joined on',
    banlance: 'Banlance',
    notInput: 'Not input yet',
    time: 'Time',
    opCode: 'Type',
    winnings: 'Winnings',
    rake: 'Rake',
    VariableAmount: 'Amount',
    gameNameID: 'Game/ID',
    Issue: 'Issue',
    Recycle: 'Recycle',
    bringIn: 'Bring in',
    games: 'Games',
    bringOut: 'Bring out',
    hands: 'Hands',
    serverFee: 'Server charge',
    IssueNum: 'Amount',
    RecycleNum: 'Amount',
    RoomRadioSet: 'Common table',
    MTTRadioSet: 'MTT',
    other: 'Other',
    tip1: '俱乐部发放玩家',
    tip2: '俱乐部回收玩家',
    tip3: '玩家盈亏值',
    tip4: '玩家所产生的服务费',
    tip5: '服务费返还'
  },
  loginPage: {
    title: '感谢您的来到',
    tip: '在这茫茫的APP中,欢迎您来到这里！',
    login: '登录',
    loginByPhone: '手机号登录',
    loginByEmail: '邮箱登录',
    success: '登录成功'
  },
  areaList: {
    'United States': 'U.S.A',
    Brazil: 'Brazil',
    Canada: 'Canada',
    Britain: 'Britain',
    France: 'France',
    Germany: 'Germany',
    Italy: 'Italy',
    Spain: 'Spain',
    Portugal: 'Portugal',
    Australia: 'Australia',
    Netherlands: 'Netherlands',
    'New Zealand': 'New Zealand',
    'Hong Kong (SAR)': 'Hong Kong, China',
    Macao: 'Macao China',
    Philippines: 'Philippines',
    'South Korea': 'Korean',
    Cambodia: 'Cambodia',
    Malaysia: 'Malaysia',
    Japan: 'Japan',
    Thailand: 'Thailand',
    Taiwan: 'Taiwan',
    'Brunei Darussalam': 'Brunei',
    Singapore: 'Singapore',
    India: 'India',
    Indonesia: 'Indonesia',
    Vietnam: 'Vietnam',
    Russia: 'Russia',
    'United Arab Emirates': 'United Arab Emirates'
  },
  clubList: {
    title: 'Club List'
  },
  applyList: {
    title: 'Apply List',
    switch: 'Enable approval free'
  },
  operation: {
    'operating time': 'operating time',
    'operation descr': 'Operation Description',
    'operation type': 'operation type',
    operator: 'Operator',
    AddOn: 'MTT purchase',
    AddOnPlus_1: 'MTT purchase Plus_1',
    AddOnPlus_2: 'MTT purchase Plus_2',
    BringIn: 'Bring In',
    BringOut: 'Bring Out',
    BuyIn: 'Buy In',
    CowboyBet: 'Cowboy Bet',
    CowboyWin: 'Cowboy Win',
    AddTime: 'props consumption - add time',
    Emoticon: 'props consumption - Emoticon',
    InsuranceIncome: 'Insurance Income',
    InsuranceIncome_1: 'Agent insurance share',
    InsurancePay: 'InsurancePay',
    InsurancePay_1: 'Agent InsurancePay',
    HandEndCheckOut: 'HandEndCheckOut',
    Insurance: 'Insurance',
    PropBuy: 'PropBuy',
    MTTHunterAward: 'MTT Hunter Reward',
    MTTCancel: 'MTT register refund',
    Lock: 'Lock UC',
    MTTAward: 'MTT Award',
    MTTPrizePay: 'Pay MTT Prize',
    PayToClub: 'Pay To Club',
    PayToTribe: 'Pay To Union',
    PayToUser: 'Pay To User',
    ProfitCowboy: 'Agent Cowboy Share',
    ProfitInsurance: 'Insurance Share',
    ProfitMTT: 'MTT Share',
    ProfitMTT_1: ' Agent MTT Share',
    ProfitOther: 'Props Share',
    ProfitOther_1: 'Agent Props Share',
    ProfitRoom: 'Common table service fee sharing',
    ProfitRoom_1: 'Agent common table service fee sharing',
    Rebuy: 'MTT Rebuy',
    Recharge: 'Platform payment to players',
    GuildRecharge: 'Club payment to players',
    clubSend: 'club gift to player',
    RechargeFromPlatform: 'Union receives platform transfer',
    RechargeFromTribe: 'Union receives platform transfer',
    Return: 'Return game',
    SystemBringOut: 'Refund the player difference',
    TakeOver: 'Platform payback players',
    Unlock: 'Unlock coins',
    ViewPCards: 'props consumption - Check the flop',
    WithdrawLock: 'Withdraw Lock',
    WithdrawRollback: 'Withdraw Rollback',
    WithdrawUnlock: 'Withdraw Unlock',
    GuildRecyclingPlayers: 'Club recycling players',
    FixNickName: 'Modification nickname income',
    FixNickName_1: 'Modification nickname',
    WorldChart: 'WorldChat Revenue',
    WorldChart_1: 'WorldChat consumption',
    'alliance transfer': 'Receive union transfer',
    联盟发放: '联盟发放',
    联盟回收: '联盟回收',
    联盟返点: '联盟返点',
    保险: '保险',
    德州牛仔分成: '德州牛仔分成',
    贵宾返点: '代理返点',
    贵宾分保险: '代理分保险',
    踢出玩家: '踢出玩家',
    clubPay: 'Issue by club',
    clubPayback: 'payback by club',
    玩家退出: '玩家退出',
    'service share': 'service share',
    'insurance share': 'insurance share ',
    'cowboy share': 'cowboy share',
    'MTT share': 'MTT share',
    'props share': 'props share',
    踢出公会: '踢出俱乐部',
    MTTPROPEXCHANGEGOLD_1: 'MTT reward redemption',
    MTTPRZPAY: 'MTT redemption payment',
    MTTPROPEXCHANGEGOLD_2: 'MTT sending rewards',
    'Player MTT Backwater': 'MTT player`s commision',
    'Player OrdinaryTable Backwater': 'Player`s commision to common tables',
    'Player MTT Backwater Tip':
      'When it is set to 100%, it means that the player will get all the regular table service fee rakeback',
    'Player OrdinaryTable Backwater Tip':
      'When it is set to 100%, it means that the player will get all MTT service fee rakeback',
    'MTT Backwater': 'MTT Commision',
    'OrdinaryTable Backwater': 'Common table`s commision',
    'Player backwater Settings': 'Player backwater Settings',
    mttPropPay: 'Platform Payment Coupons',
    mttFreePay: 'Platform payments are free for a limited time',
    SnatchTreasureAwardd: 'Cashback on Super 1',
    cowboyBringIn: 'cowboy buy in',
    cowboyBringOut: 'cowboy brought out',
    videoTableFee: 'Video Table Charges',
    videoTableFeeReturn: 'Return Video Table Charges',
    PLATRECOVETRIBE: 'Platform Recycling Union',
    TRIBETOCLUB: 'Union Issuing Clubs',
    TRIBERECOVECLUB: 'Union Recycling Clubs',
    TRIBETOUSER: 'Union Issuing Players',
    PLATCommission: 'Platform Service Fee',
    PLATCommissionRakeback: 'Platform Service Fee Refund',
    transDiamond: 'Exchange diamond',
    TRIBEUSERLOGQUERY: 'Integrated Query Search',
    TRIBEUSERLOGDOWN: 'Integrated Query Export',
    TRIBEDEDUCT: 'Union fines players',
    TRIBEREFUND: 'Union Refunds Players',
    PLATPFT: 'System Deduction',
    PLATINSURIN: 'Insurance System Deduction',
    PLATINSUROUT: 'Insurance System Compensation',
    TRIBEMULTILANG: 'Setting Multilingual Fees',
    ROOMCHATFEE: 'Chat table charges',
    ROOMCHATFEERTN: 'Chat Table Charges Refund',
    MTTRECORD: 'MTT recording fee',
    MTTRECRTN: 'MTT recording fee refund',
    MTTVIDEOFEE: 'MTT video table',
    MTTCREATE: 'Create MTT',
    TRIBEMTTSTATSDOWN: 'MTT Data Export',
    TRIBEKOCLUBTIN: '联盟踢出俱乐部',
    TRIBEKOCLUBCIN: '联盟踢出俱乐部(回收玩家余额)',
    TRIBEKOCLUBCOUT: '联盟踢出俱乐部',
    TRIBEKOCLUBUOUT: '联盟踢出俱乐部',
    RECSELFPFT: '收到定期个人返水'
  }
}
