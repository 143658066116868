import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //     path: "/login",
  //     name: "login",
  //     component: login,
  // },
  { path: '/', redirect: { name: 'login' } },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/clubList',
    name: 'clubList',
    component: () => import('../views/clubList.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/profit',
    name: 'profit',
    component: () => import('../views/profit.vue')
  },
  {
    path: '/vipDashboard',
    name: 'vipDashboard',
    component: () => import('../views/vipDashboard.vue')
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import('../views/vip.vue')
  },
  {
    path: '/SharedData',
    name: 'sharedData',
    component: () => import('../views/SharedData.vue')
  },
  {
    path: '/sharedDataUnion',
    name: 'sharedDataUnion',
    component: () => import('../views/sharedData/union.vue')
  },
  {
    path: '/sharedDataClub',
    name: 'sharedDataClub',
    component: () => import('../views/sharedData/club.vue')
  },
  {
    path: '/sharedDataPlayer',
    name: 'sharedDataPlayer',
    component: () => import('../views/sharedData/player.vue')
  },
  {
    path: '/daily',
    name: 'reportdaily',
    component: () => import('../views/report/daily.vue')
  },
  {
    path: '/weekly',
    name: 'reportweekly',
    component: () => import('../views/report/weekly.vue')
  },
  {
    path: '/userList',
    name: 'userList',
    component: () => import('../views/userList/list.vue')
  },
  {
    path: '/applyList',
    name: 'applyList',
    component: () => import('../views/userList/applyList.vue')
  },
  {
    path: '/userWallet',
    name: 'userWallet',
    component: () => import('../views/userList/wallet.vue')
  },
  {
    path: '/userDetail',
    name: 'userDetail',
    component: () => import('../views/userList/detail.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  //   if (to.path == '/login') return next()
  //   const tokenstr = window.localStorage.getItem('token')
  //   if (!tokenstr) {
  // return next('/login')
  //   }
  next()
})
export default router
