import axios from 'axios'

const isDev = process.env.NODE_ENV === 'development'

export const config = {
    // 上传图片地址
    uploadApi: '',
    // /api
    baseApiNew: '',
    /// 基础请求地址
    baseApi: '',
    // 部署web地址
    webHost: '',
    // 老接口地址
    oldBaseApi: '',
    // 图片地址
    imageApi: '',
    // 是否正式环境
    isTest: '',
    // ipa下载地址
    ipaUrl: '',
}

if (isDev) {
    // don't merge this in prod to avoid merging in build
    Object.assign(config, require('@/../public/config.json'))
}

export const init = async() => {
    // if (config.uploadApi) return
    //  in dev its imports local config file
    const { data } = await axios.get(`/config.json?_=${ Date.now() }`)
    Object.assign(config, data) // merge configs
}

export default config