import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import Vant, { ActionSheet } from 'vant'
import 'vant/lib/index.less'
import './styles/base.css'
import moment from 'moment'
import VueI18n from 'vue-i18n'
import { init as CONFIG_INIT } from '@/config/config'

Vue.prototype.$moment = moment

Vue.use(VueI18n)
const query = {}
const hash = window.location.hash.split('?')
if (hash.length > 1) {
  const queryList = hash[1].split('&')
  queryList.forEach(item => {
    const obj = item.split('=')
    query[obj[0]] = obj[1]
  })
}
const i18n = new VueI18n({
  locale: window.localStorage.getItem('language') || query.lang || 'cn', //语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    en: require('./common/en'), // 英文语言包
    cn: require('./common/cn'), // 英文语言包
    pt: require('./common/pt') // 葡语语言包
  }
})
Vue.use(Vant)
Vue.use(ActionSheet)

const initApp = async () => {
  await CONFIG_INIT()
  Vue.config.productionTip = false
  window.vue = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}
initApp()
