module.exports = {
  common: {
    all: '全部',
    checkDetail: '查看详情',
    save: '保存',
    submit: '提交',
    close: '关闭',
    agree: '同意',
    refuse: '拒绝',
    oprationSuccess: '操作成功',
    loginFail: '登入失敗',
    noPermission: '权限不足'
  },
  dashboard: {
    totalbalance: '总余额',
    earnings: '今日盈利',
    yesterdayEarnings: '昨日盈利',
    weekEarnings: '近七日盈利',
    'Today Deposit': '今日充值',
    'Today Withdraw': '今日提现',

    activeClubs: '活跃俱乐部',
    newClubs: '新增俱乐部',
    totalClubs: '累计俱乐部',
    activeVips: '活跃代理',
    newVips: '新增代理',
    totalVips: '累计代理',
    activeUsers: '活跃用户',
    newUsers: '新增用户',
    totalUsers: '累计用户',

    totalServerFee: '总服务费',
    mttProfit: 'MTT收益',
    'super1 profit': '一元购收益',
    'Ordinary table profit': '普通桌收益',
    todayHands: '今日手数',
    todayGames: '今日局数',

    unionProfit: '联盟收益',
    clubProfit: '俱乐部收益',
    vipDataDashboard: '代理数据看板',
    vipData: '代理数据',
    sharedData: '分成数据'
  },
  vip: {
    statistics: '代理收益统计',
    account: '账号',
    password: '密码',
    ForgotPassword: '忘记密码',
    customer: '请联系客服',
    login: '登录',
    ResetPassword: '重置密码',
    passwordSecurity: '为了账户安全请重置密码',
    English: '英语',
    Portuguese: '葡萄牙语',
    Chinese: '简体中文',
    Setlanguage: '设置语言',
    TotalRevenue: '总收益',
    TotalFriends: '总好友',
    LoginExpired: '登录已过期，请重新登录',
    nextUpdate: '下次更新时间',
    revenue: '收益',
    newAdd: '新增',
    Active: '活跃',
    day: '可查看近七天数据',
    Error: '账号/密码错误',
    cancel: '取消',
    logOut: '注销',
    money: '$',
    completed: '完成',
    Actualizar: '数据每1小时更新一次'
  },
  Today: '今日',
  Days7: '近7日',
  Days30: '近30日',
  timeZoneTip: '时区:UTC-0',
  menu: {
    vipStatistics: '代理收益',
    SharedData: '分成数据',
    MemberManagement: '成员管理'
  },
  shareData: {
    'ordinary table': '普通桌',
    mtt: 'MTT',
    super1: '一元購',
    'regular table attendance': '普通桌人次',
    active_head_time: '活躍人頭',
    'final result': '最終戰績',
    'Ordinary tables commission': '普通桌返水',
    mttPersonTimes: 'MTT人次',
    'service fee': '服務費',
    ordinary_table_num: '普通桌桌數',
    addons_nums: 'addon次數',
    'person times': '人次',
    'head times': '人頭',
    Winnings: '中獎數',

    total_games: '总局数',
    'total agents nums': '累计代理数',
    'affiliated vip': '所属贵宾',
    participants_game_num: '参与比赛场数',
    participants_num: '参与次数',
    buy_times: '购买次数',

    'hand name': '牌局名称',
    'hand id': '牌局ID',
    'match id': '赛事ID',
    participants_product_name: '参与商品名称',
    participants_product_id: '参与商品ID',

    'original record': '原始战绩',
    is_winner: '是否中奖'
  },
  placeholder: {
    memberSearch: '请输入玩家昵称或ID',
    shareDataSearch: '请输入名称或ID搜索',
    memberType: '请选择玩家类型',
    phone: '请输入手机号码',
    password: '请输入密码',
    email: '请输入邮箱'
  },
  daily: {
    title: '数据日报',
    compare: '与昨日同比',
    compare2: '同比昨日',

    profit: '今日收益',
    active_user: '活跃人数',
    hands: '今日手数',
    add_user: '今日新增(人数)',
    tables: '总桌数/活跃桌数',
    open_tables: '总桌数',
    avtive_tables: '活跃桌数',
    inavtive_tables: '非活跃桌数',
    user: '直客',
    agent: '代理',
    line7: '7日走势',
    line7_oyo: '同比7天走势',

    UCAccount: 'UC账户',
    profitData: '收益数据',
    gameTypeData: '玩法数据',
    userData: '用户数据',
    account_banlance: '账户余额',
    club_send: '俱乐部发放',
    club_recyle: '俱乐部回收',
    buy_uc: '购买UC',
    shared_uc: '下分UC',

    total_profit: '总收益',
    vip_user_profit: '代理玩家抽水',
    user_profit: '直客玩家抽水',
    room_profit: '普通桌抽水',
    mtt_profit: 'MTT收益',
    vip_insurance_profit: '代理玩家保险收益',
    insurance_profit: '直客玩家保险收益',
    user_back_profit: '玩家返点',

    total_hand_num: '总手数',
    nlh_hand_num: '德州',
    plo_hand_num: '奥马哈',
    six_plus_hand_num: '6+',
    vip_user_hand_num: '代理玩家',
    user_hand_num: '直客玩家',

    active_user_count: '活跃玩家',
    add_user_count: '新增人数',
    three_day_user_count: '3日留存',
    seven_day_user_count: '7日留存'
  },
  userList: {
    title: '成员管理',
    agentTitle: '下线成员',
    searchPlaceholde: 'ID、暱稱、備註',
    ucBanlance: 'UC余额',
    diamondBanlance: '钻石余额',
    nameID: '昵称/ID',
    win: '赢',
    hands: '手数',
    serverFee: '服务费',
    insurance: '保险',
    lastLogin: '登录',
    total: '合计',
    diamond: '钻石',
    roomRB: '普通桌返点',
    mttRB: 'MTT返点',
    placeholder1: '请输入发放数量',
    placeholder2: '请输入回收数量',
    search: '搜索',
    noData: '暂无更多数据',
    confirm: '确认',
    rateTip: '注:当前货币与USD根据XE.com实时汇率进行换算'
  },
  userDetail: {
    title: '成员详情',
    joinTime: '加入时间',
    banlance: '余额',
    notInput: '暂未填写',
    time: '时间',
    opCode: '类型',
    winnings: '赢',
    rake: '抽水',
    VariableAmount: '数量',
    gameNameID: '牌局名称/ID',
    Issue: '发放',
    Recycle: '回收',
    bringIn: '带入',
    bringOut: '带出',
    games: '游戏数',
    hands: '手数',
    serverFee: '服务费',
    IssueNum: '数量',
    RecycleNum: '金额',
    RoomRadioSet: '设置普通返点',
    MTTRadioSet: '设置MTT返点',
    other: '其他',
    tip1: '俱乐部发放玩家',
    tip2: '俱乐部回收玩家',
    tip3: '玩家盈亏值',
    tip4: '玩家所产生的服务费'
  },
  loginPage: {
    title: '感谢您的来到',
    tip: '在这茫茫的APP中,欢迎您来到这里！',
    login: '登录',
    loginByPhone: '手机号登录',
    loginByEmail: '邮箱登录',
    success: '登录成功'
  },
  areaList: {
    'United States': '美国',
    Brazil: '巴西',
    Canada: '加拿大',
    Britain: '英国',
    France: '法国',
    Germany: '德国',
    Italy: '意大利',
    Spain: '西班牙',
    Portugal: '意大利',
    Australia: '澳大利亚',
    Netherlands: '荷兰',
    'New Zealand': '新西兰',
    'Hong Kong (SAR)': '中国香港',
    Macao: '中国澳门',
    Philippines: '菲律宾',
    'South Korea': '韩国',
    Cambodia: '柬埔寨',
    Malaysia: '马来西亚',
    Japan: '日本',
    Thailand: '泰国',
    Taiwan: '中国台湾',
    'Brunei Darussalam': '文莱',
    Singapore: '新加坡',
    India: '印度',
    Indonesia: '印度尼西亚',
    Vietnam: '越南',
    Russia: '俄罗斯',
    'United Arab Emirates': '阿拉伯联合酋长国'
  },
  clubList: {
    title: '俱乐部列表'
  },
  applyList: {
    title: '申请列表',
    switch: '开启免审'
  },
  operation: {
    'operating time': '操作時間',
    'operation descr': '操作描述',
    'operation type': '操作類型',
    operator: '操作人',
    AddOn: 'MTT增購',
    AddOnPlus_1: 'MTT增購Plus1',
    AddOnPlus_2: 'MTT增購Plus2',
    BringIn: '普通桌-帶入',
    BringOut: '普通桌-帶出',
    BuyIn: 'MTT報名',
    CowboyBet: '牛仔下注',
    CowboyWin: '牛仔盈利',
    AddTime: '道具消費-加時間',
    Emoticon: '道具消費-表情',
    InsuranceIncome: '保險收入',
    InsuranceIncome_1: '代理保險分成',
    InsurancePay: '保險賠付',
    InsurancePay_1: '代理保險賠付',
    HandEndCheckOut: '每手結束結賬',
    Insurance: '保險記錄',
    PropBuy: 'MTT道具代替買入',
    MTTHunterAward: 'MTT獵人獎勵',
    MTTCancel: 'MTT報名退費',
    Lock: '鎖定UC',
    MTTAward: 'MTT獎勵',
    MTTPrizePay: '支付MTT獎勵',
    PayToClub: '聯盟轉給俱樂部',
    PayToTribe: '平台發放聯盟',
    PayToUser: '平台發放玩家',
    ProfitCowboy: '代理牛仔分成',
    ProfitInsurance: '保險分成',
    ProfitMTT: 'MTT服務費分成',
    ProfitMTT_1: '代理MTT服務費分成',
    ProfitOther: '道具分成',
    ProfitOther_1: '代理道具分成',
    ProfitRoom: '普通桌服務費分成',
    ProfitRoom_1: '代理普通桌服務費分成',
    Rebuy: 'MTT重購',
    Recharge: '平台發放玩家',
    GuildRecharge: '俱樂部發放玩家',
    clubSend: '俱樂部贈送玩家',
    RechargeFromPlatform: '聯盟收到平台轉賬',
    RechargeFromTribe: '俱樂部收到聯盟轉賬',
    Return: '返回游戲',
    SystemBringOut: '卡桌差額返還玩家',
    TakeOver: '平台回收玩家',
    Unlock: '解鎖金幣',
    ViewPCards: '道具消費-查看翻牌',
    WithdrawLock: '提現鎖定',
    WithdrawRollback: '提現失敗，返還',
    WithdrawUnlock: '玩家提現解鎖',
    GuildRecyclingPlayers: '俱樂部回收玩家',
    FixNickName: '玩家修改暱稱收入',
    FixNickName_1: '修改暱稱',
    WorldChart: '世界聊天收入',
    WorldChart_1: '世界聊天消費',
    'alliance transfer': '聯盟發放',
    联盟回收: '聯盟回收',
    联盟返点: '聯盟返點',
    保险: '保險',
    德州牛仔分成: '德州牛仔分成',
    贵宾返点: '代理返點',
    贵宾分保险: '代理分保險',
    踢出玩家: '踢出玩家',
    clubPay: '俱樂部發放',
    clubPayback: '俱樂部回收',
    玩家退出: '玩家退出',
    'service share': '服務費分成',
    'insurance share': '保險分成',
    'cowboy share': '牛仔分成',
    'MTT share': 'MTT分成',
    'props share': '道具分成',
    TRIBETOCLUB: '聯盟發放俱樂部',
    TRIBERECOVECLUB: '聯盟回收俱樂部',
    踢出公会: '踢出俱樂部',
    MTTPROPEXCHANGEGOLD_1: 'MTT獎勵兌換',
    MTTPRZPAY: '補發MTT獎勵',
    MTTPROPEXCHANGEGOLD_2: '發放MTT獎勵兌換',
    'Player MTT Backwater': '玩家MTT返水',
    'Player OrdinaryTable Backwater': '玩家普通桌返水',
    'Player MTT Backwater Tip': '設置為100%時，則表示玩家獲得MTT服務費全部返水',
    'Player OrdinaryTable Backwater Tip':
      '設置為100%時，則表示玩家獲得普通桌服務費全部返水',
    'MTT Backwater': 'MTT返水：',
    'OrdinaryTable Backwater': '普通桌返水：',
    'Player backwater Settings': '玩家返水設置',
    mttPropPay: '平台支付代金券',
    mttFreePay: '平台支付限免',
    SnatchTreasureAwardd: '一元奪寶獎品折現',
    cowboyBringIn: '牛仔買入',
    cowboyBringOut: '牛仔帶出',
    videoTableFee: '视频桌收费',
    videoTableFeeReturn: '退还视频桌收费',
    PLATRECOVETRIBE: '平台回收聯盟',
    TRIBETOUSER: '联盟发放玩家',
    PLATCommission: '平台抽水',
    PLATCommissionRakeback: '平台抽水返回',
    transDiamond: '轉換鑽石',
    TRIBEUSERLOGQUERY: '综合查询搜索',
    TRIBEUSERLOGDOWN: '综合查询导出',
    TRIBEDEDUCT: '联盟罚没玩家',
    TRIBEREFUND: '联盟退还玩家',
    PLATPFT: '系统扣减',
    PLATINSURIN: '保险系统扣减',
    PLATINSUROUT: '保险系统赔付',
    TRIBEMULTILANG: '设置多语言收费',
    ROOMCHATFEE: '聊天桌收费',
    ROOMCHATFEERTN: '退还聊天桌收费',
    MTTRECORD: 'MTT记录费',
    MTTRECRTN: 'MTT记录费退还',
    MTTVIDEOFEE: 'MTT视频桌',
    MTTCREATE: '创建MTT',
    TRIBEMTTSTATSDOWN: 'MTT数据导出',
    TRIBEKOCLUBTIN: '联盟踢出俱乐部',
    TRIBEKOCLUBCIN: '联盟踢出俱乐部(回收玩家余额)',
    TRIBEKOCLUBCOUT: '联盟踢出俱乐部',
    TRIBEKOCLUBUOUT: '联盟踢出俱乐部',
    RECSELFPFT: '收到定期个人返水'
  }
}
