module.exports = {
  common: {
    all: 'Todos',
    checkDetail: 'detalhes'
  },
  dashboard: {
    totalbalance: 'Balanço total',
    earnings: 'Lucro de hoje',
    yesterdayEarnings: 'Lucro de ontem',
    weekEarnings: 'Lucro nos últimos sete dias',
    'Today Deposit': 'Recarregue hoje',
    'Today Withdraw': 'Retirar hoje',

    activeClubs: 'Clubes ativos',
    newClubs: 'Novos clubes',
    totalClubs: 'Clubes cumulativos',
    activeVips: 'Agentes ativos',
    newVips: 'Novos agentes',
    totalVips: 'Agências cumulativas',
    activeUsers: 'Usuários ativos',
    newUsers: 'Novos usuários',
    totalUsers: 'Usuários cumulativos',

    totalServerFee: 'Taxa total de serviço',
    mttProfit: 'Lucro MTT',
    'super1 profit': 'Super 1 lucro de compra',
    'Ordinary table profit': 'Lucro mesa normal',
    todayHands: 'Mãos de hoje',
    todayGames: 'Jogos de hoje',

    unionProfit: 'Lucro da união',
    clubProfit: 'Lucros do clube',
    vipDataDashboard: 'Painel de dados do agente',
    vipData: 'Dados do agente',
    sharedData: 'Dados de rakeback'
  },
  vip: {
    statistics: 'Estatísticas de lucros dos VIPs', //贵宾收益统计
    account: 'Nome de usuário', //账号
    password: 'senha', //密码
    ForgotPassword: 'Esqueceu a senha', //忘记密码
    customer: 'Contate-Nos', //请联系客服
    login: 'O Login', //登录
    ResetPassword: 'Redefinir senha', //重置密码
    passwordSecurity: 'Redefinir sua senha para segurança da conta', //为了账户安全请重置密码
    English: 'Inglês', //英语
    Portuguese: 'Português', //葡萄牙语
    Chinese: 'Chinês simplificado', //简体中文
    Setlanguage: 'Linguagem', //设置语言
    TotalRevenue: 'Rendimento total', //总收益
    TotalFriends: 'Total de amigos', //总好友
    LoginExpired: 'O login expirou. Por favor faça login novamente', //登录已过期，请重新登录
    nextUpdate: 'Hora da próxima atualização', //下次更新时间
    revenue: 'Rendimento', //收益
    newAdd: 'Nova adição', //新增
    Active: 'Atividade', //活跃
    day: 'Os dados são atualizados a cada hora', //可查看近七天数据
    Error: 'Erro de conta/senha', //账号/密码错误
    cancel: 'Cancelar', //取消
    logOut: 'Sair', //注销
    money: 'R$',
    completed: 'concluído', //完成
    Actualizar: 'Actualizar os dados a cada 1 hora' //数据每1小时更新一次
  },
  statistics: {
    login: 'Estatísticas CMS'
  },
  Today: 'Hoje',
  menu: {
    vipStatistics: 'lucros dos VIPs',
    SharedData: 'Dados Compartilhados',
    MemberManagement: 'Gerenciamento de membros'
  },
  member: {
    list: '成员列表',
    applyList: '申请列表',
    admin: '管理员',
    member: '成员',
    gold: '金豆',
    usdt: 'USDT'
  },
  shareData: {
    'ordinary table': 'Mesa comum',
    mtt: 'MTT',
    super1: 'Super1',
    'regular table attendance': 'Atendimento mesa comum',
    active_head_time: 'cabeça ativa',
    'final result': 'Pontuação final',
    'Ordinary tables commission': 'Desconto de mesa normal',
    mttPersonTimes: 'pessoa-vezes',
    'service fee': 'Taxa de serviço',
    ordinary_table_num: 'Número de mesas comuns',
    addons_nums: 'Número de addons',
    'person times': 'Pessoa-tempo',
    'head times': 'Número de pessoas',
    Winnings: 'Número de vencedores',

    total_games: 'Jogos totais',
    'total agents nums': 'Número total de agentes',
    'affiliated vip': 'VIP afiliada',
    participants_game_num: 'Número de partidas disputadas',
    participants_num: 'Tempos de participação',
    buy_times: 'Número de compras',

    'hand name': 'Nome do jogo',
    'hand id': 'ID do jogo',
    'match id': 'ID do torneio',
    participants_product_name: 'Nome do produto participante',
    participants_product_id: 'ID do produto participante',
    is_winner: 'Ganhador',
    'original record': 'Recorde original '
  },
  placeholder: {
    memberSearch: 'Insira o apelido ou ID do jogador',
    shareDataSearch: 'Digite um nome ou ID para pesquisar',
    memberType: 'Selecione o tipo de jogador'
  },
  daily: {
    title: '数据日报',
    compare: '与昨日同比',
    compare2: '同比昨日',

    profit: '今日收益',
    active_user: '活跃人数',
    hands: '今日手数',
    add_user: '今日新增(人数)',
	table:'总桌数/活跃桌数',
    tables: '总桌数/活跃桌数',
	open_tables: '总桌数',
    avtive_tables: '活跃桌数',
	inavtive_tables: '非活跃桌数',
    user: '直客',
    agent: '代理',
    line7: 'tendência de 7 dias',
    line7_oyo: '7-Tage-Trend gegenüber dem Vorjahr',

    UCAccount: 'UC账户',
    profitData: '收益数据',
    gameTypeData: '玩法数据',
    userData: '用户数据',
    account_banlance: '账户余额',
    club_send: '俱乐部发放',
    club_recyle: '俱乐部回收',
    buy_uc: '购买UC',
    shared_uc: '下分UC',

    total_profit: '总收益',
    vip_user_profit: '代理玩家抽水',
    user_profit: '直客玩家抽水',
    room_profit: '普通桌抽水',
    mtt_profit: 'MTT收益',
	vip_insurance_profit: '代理玩家保险收益',
    insurance_profit: '直客玩家保险收益',
    user_back_profit: '玩家返点',

    total_hand_num: '总手数',
    nlh_hand_num: '德州',
    plo_hand_num: '奥马哈',
    six_plus_hand_num: '6+',
    vip_user_hand_num: '代理玩家',
    user_hand_num: '直客玩家',

    active_user_count: '活跃玩家',
    add_user_count: '新增人数',
    three_day_user_count: '3日留存',
    seven_day_user_count: '7日留存'
  },
  userList: {
    title: '成员管理',
    searchPlaceholde: 'ID,昵称,备注',
    nameID: '名称/ID',
    win: '赢',
    hands: '手数',
    serverFee: '服务费',
    insurance: '保险',
    lastLogin: '登录',
    total: '合计',
    diamond: '钻石',
    roomRB: '普通桌返点',
    mttRB: 'MTT返点',
	placeholder:'请输入{type}变动数量'
  }
}
