import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)
//vuex 状态持久化
const vuexLocal = new VuexPersistence({
  storage: window.localStorage.getItem('token')
})

const store = new Vuex.Store({
  state: {
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    club_id: localStorage.getItem('club_id') || '0',
    club_info: {}
  },
  mutations: {
    changeToken (state, info) {
      state.token = info.token
      localStorage.setItem('token', info.token)
    },
    changeClubInfo (state, info) {
      state.club_id = info.club_id
      state.club_info = info
      localStorage.setItem('club_id', info.club_id)
      localStorage.setItem('club_info', JSON.stringify(info))
    }
  },
  actions: {},
  getters: {
    token: state => state.token,
    club_id: state => state.club_id,
    clubInfo: state => {
      return state.club_info.club_id
        ? state.club_info
        : JSON.parse(localStorage.getItem('club_info'))
    }
  },
  modules: {}
})
export default store
